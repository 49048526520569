/*oE (vsechny stranky)*/
$zahlavipozadi: #FDFABE;
$zahlavitext: #000000;
$nazev: #5C5C46;
$dodatek: #000000;
$zahlaviodkazy: #000000;
$vyhledavaniobrys: #D7BE1E;
 
$hlavninadpisy: #5C5C46;
 
$menupozadi: #EBD232;
$menuzvyraznenipozadi: #000000;
$menutlacitkapozadi: #454533;
$menutlacitkatext: #FDFAAA;
$menuzvyraznenitext: #EBD232;
 
$upozornenipozadi: #353522;
$upozorneninadpisy: #EBD232;
$upozornenitext: #D5DFC1;
$upozorneniodkazy: #EBD232;
 
$aktuality1nadpisy: #5C5C46;
$linky: #EBD232;
$bileplochyodkazy: #5C5C46;
 
$deskalinky: #EBD232;
 
$kalendarakcipozadi: #EBD232;
$akcehlavninadpis: #52523C;
$akcenadpisy: #353522;
$kalendarakcitext: #000000;
$akcedoplnkovytext: #000000;
$odkazyTabulekOstatni: #425542;
$odkazyTabulekAktualni: #FDFAAA;
$bunkyTabulekOstatni: #FDFABE;
$bunkyTabulekAktualni: #454533;
$akcetlacitkapozadi: #454533;
$akcetlacitkatext: #FFFECC;
$akceodkazy: #353522;
 
$formularepozadi: #FDFABE;
$formularetext: #233323;
$formularenadpis: #5C5C46;
$polepozadi: #FFFEF1;
$poletext: #425542;
$poleobrysy: #D7BE1E;
 
$tlacitkapozadi: #454533;
$tlacitkatext: #FFFECC;
 
$vysokapatapozadi: #454533;
$vysokapatatext: #D5DFC1;
$vysokapataodkazy: #D5DFC1;
$vysokapatanadpisy: #EBD232;
$vysokapatasymboly: #EBD232;
 
$patapozadi: #F8F5B9;
$patatext: #272727;
$pataodkazy: #324232;
