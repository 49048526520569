
/*****************************
* FORMULÁŘE
*****************************/

/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/

%hiding-label {
	line-height: 1.2;
	text-align: left;
	padding: 3px 2px;
	display: block;

	&.label-skryty {
		padding: 0 2px;
		position: relative;
		
		&::after {
			@extend %fill;
			content: "";
			z-index: 2;
		}
	}
}

/****************************/


/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/

.format {
	background-color: $polepozadi;
	color: $poletext;
	height: $format-height;
	border-radius: 0;
	line-height: ( 17 / 14 );
	font-size: rem(15px);

	&:focus {
		border-color: darken($poleobrysy, 20%);
	}
}

#fktg, .fhledani{
	display: none!important; 
}

.format,
fieldset {
	border: 1px $poleobrysy solid;
}

:not(.fvlevo) > .fvyraz #hv {
	font-size: rem(19px);
	height: $format-main-height;
	border-width: 2px;
}

textarea.format {
	min-height: $format-height * 2;
}

select.format[multiple] {
	height: $format-height * 3;
}

.btn {
	background-color: $tlacitkapozadi;
	border: $tlacitkapozadi;
	color: $tlacitkatext;
	height: $btn-height;
}

.fbtn .btn {
	padding: 0 2em;
	font-size: rem(16px);
}

/************************************************/

.fkont {
	margin-top: 20px;

	form {
		padding: $form-padding;
	}

	:not(.fvlevo) > .fvyraz label[for="hv"] {
		font-size: rem(16px);
	}
}

.formular,
.fkont form,
.nastaveni {
	background-color: $formularepozadi;
	color: $formularetext;

	@include link {
		color: $formularetext;
	}
}

//zjistit, kde to vůbec je kromě diskuze
.fcesta,
#{selector-link-all(".fcesta a")} {
	background-color: $th-background;
	color: $th-text;
}


/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/

.wrapper-abo {
	background-color: $formularepozadi;
}

.titulformular {
	text-align: center;

	label {
		@extend %hiding-label;
		display: inline-block;
		margin-top: 15px;

		&.label-skryty {
			margin-top: 0;

			&::after {
				background: transparent url(images/frm_prekryti.svg) repeat 0 0;
			}
		}
	}

	.format {
		margin: 2px 0;
		padding: 0 15px;
		width: 100%;
		height: 41px;
		color: $poletext;
		border-width: 2px;
		//border-color: blue;
		background: {
			repeat: no-repeat;
			position: 0 0;
		}

		&:focus {
			color: darken($poleobrysy, 10%);
		}

		&[name="fabo"] {
			max-width: 430px;
			text-align: center;
			background-position: 50% 0;
		}
	}

	textarea.format {
		padding: 10px 15px;
	}

	img {
		position: absolute;
		right: 0;
		top: 0;
	}

	.btn {
		height: auto;
		font-size: rem(16px);
		text-transform: uppercase;
		border: 0;
		float: none;

		@include bp("<= 500px") {
			width: 100%;	
		}

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	
	p {
		padding: 0;
		margin: 0;
	}
}

/*#napiste {
	max-width: 100%;

	&[class*="toggled"] {
		width: 100%;
		max-width: 700px;
		background-color: #DEE1DA; 
		border-radius: 4px;
		overflow: hidden;
		padding-bottom: 34px;
		z-index: 40;

		textarea {
			border-width: 0 0 2px 0;
			padding: 10px 30px;
			min-height: 164px;
		}

		input.format {
			float: left;
			box-sizing: border-box;
			@include grid-column(2, 3%);
		}

		.jmeno-mail {
			padding-left: 30px;
			padding-right: 30px;
		}

		h2.cvi {
			@extend %vh;
		}

		.napiste-close-wrapper {
			background-color: $color-main;	
			padding: 7px;
			text-align: right;
		}

		.napiste-close-link {
			width: 21px;
			height: 19px;
			display: inline-block;
			cursor: pointer;

			&::before {
				content: encode-svg('<svg width="100%" height="100%" viewBox="0 0 16 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><path d="M0.75,13.5l14.25,-12.75l-14.25,12.75" style="fill:none;stroke:#000;stroke-width:1.5px;"/><path d="M15,13.5l-14.25,-12.75l14.25,12.75" style="fill:none;stroke:#000;stroke-width:1.5px;"/></svg>');
			}
		}
	}
}
*/
/***************
*	HLEDÁNÍ
***************/

#hledani {
	//overflow: hidden;
	clear: both;
	margin-top: 15px;

    @include bp("> 600px") {
        float: left;
    }

	.pole {
		position: relative;
		z-index: 5;
        width: calc(100% - 56px);

        @include bp("> 600px") {
            float: right;
            width: 328px;
        }
	}
	
	label {
		@extend %hiding-label;
		text-align: right;
		display: inline-block;

		&.label-skryty::after {
			background: transparent url(images/hl_prekryti.svg) repeat 0 0;
		}
	}


	input:focus {
		outline: 0;
	}
	
	.format {
		width: 100%;
		margin: 0;
		height: 36px;
		border-width: 2px;
		border-color: $vyhledavaniobrys;
		color: $poletext;
		padding: 0 36px 0 10px;
		background: {
			repeat: no-repeat;
			position: 0 0;
		}
	}

	.btn {
		@extend %reset;
		border: 0;
		position: absolute;
		z-index: 2;
		top: 6px;
		right: 6px;
		height: auto;
		border-radius: 0;
		color: $poletext;
		background-color: transparent;
	}
}
