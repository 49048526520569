@charset "UTF-8";
/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/
/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/
/*********************************/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
#lista ul, .dok ul, .obrodkazy .ui, .titulodkazy .ui, .zalozky ul, .bezjs ul, #menu1-vybery ul, #menu1 ul, #menu ul, body, .obrodkazy li, #hledani .btn, #keskryti, #titul .zalozky li, .mesice {
  margin: 0;
  padding: 0;
}

#lista ul, .dok ul, .obrodkazy .ui, .titulodkazy .ui, .zalozky ul, .bezjs ul, #menu1-vybery ul, #menu1 ul, #menu ul {
  list-style-type: none;
}

#nazev a::after, .titulformular label.label-skryty::after, #hledani label.label-skryty::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#stred,
#vpravo,
#vlevo, .odkazy .ui li li li::before,
.aktuality2 li.bezobr li li::before,
.utvary .ui li.o li li::before,
.kategorie .ui li li li::before,
.utvary .ui li li li::before,
.galerie .ui li li li::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.centrovany::after, #hlava::after, #obalobrazek::after, #lista::after, .wrapper-novinky .dok ul::after, .zalozky ul::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
/*oE (vsechny stranky)*/
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*************************
* PROMĚNNÉ KOMPONENT
*************************/
/** TYPOGRAFIE **/
/** FORMULÁŘE **/
/** TABULKY **/
/** SEZNAMY **/
/** AKCE **/
/** KALENDÁŘE **/
/** KALENDÁŘE **/
/** ZÁLOŽKY **/
/************************/
/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 1rem;
  padding: 0;
  line-height: 1.2;
  margin: 0 20px;
  font-weight: normal;
  vertical-align: bottom;
}

@media screen and (min-width: 601px) {
  h1.cvi {
    padding-top: 30px;
  }
}

h1.cvi strong {
  display: block;
  font-family: TekoWeb;
  font-size: 3.25rem;
  line-height: .75;
  margin: 0 8px 10px 0;
  color: #5C5C46;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 501px) {
  h1.cvi strong {
    font-size: 11vw;
  }
}

@media screen and (min-width: 601px) {
  h1.cvi strong {
    font-size: 4rem;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 901px) {
  h1.cvi strong {
    font-size: 4.5rem;
  }
}

@media screen and (min-width: 601px) {
  h1.cvi {
    margin-left: 110px;
  }
  .bez-znaku h1.cvi {
    margin-left: 0;
  }
  h1.cvi strong {
    display: inline;
  }
}

h1.cvi .hsub {
  white-space: nowrap;
  color: #000000;
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
  font-family: TekoWeb;
}

h2.cvi,
.poz h2.cist {
  font-size: 2.625rem;
  color: #5C5C46;
  font-weight: 600;
  margin: 0 0 18px 0;
  padding: 0;
  line-height: 1;
  text-align: center;
}

@media screen and (min-width: 701px) {
  h2.cvi,
  .poz h2.cist {
    font-size: 3.75rem;
  }
}

h2.cvi.cvi--mensi,
.poz h2.cist.cvi--mensi {
  font-size: 2.5rem;
}

#zahlavi h2.cvi {
  margin: 0 0 30px 0;
  padding: 0;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: 2.5rem;
}

@media screen and (min-width: 801px) {
  #zahlavi h2.cvi,
  #zalozkynadpis h2.cvi {
    text-align: left;
    font-size: 3.75rem;
  }
}

.editor h3, h3.cvi, h3.ud,
#prohlaseni h3 {
  font-size: 1.75rem;
  font-weight: normal;
  margin: 25px 0 5px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
}

h4 {
  font-size: 1.3125rem;
  line-height: 1.2;
}

h4.ud + .udz {
  margin-top: 28px;
}

/*oE (vsechny stranky + editor)*/
@font-face {
  font-family: "TekoWeb";
  font-weight: normal;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-regular-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-regular-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-regular-web.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "TekoWeb";
  font-weight: 300;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-light-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-light-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-light-web.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "TekoWeb";
  font-weight: 500;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-medium-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-medium-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-medium-web.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "TekoWeb";
  font-weight: bold;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-semibold-web.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-semibold-web.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/teko-semibold-web.ttf") format("truetype");
  font-display: swap;
}

html {
  line-height: 1.375;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 18px;
}

@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #FFFFFF;
}

.editor h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: TekoWeb;
  line-height: 1.2;
}

body,
.editor {
  font-size: 1em;
}

body,
.automat {
  background-color: #f3f3f3;
  color: #444444;
}

a,
a:link,
a:visited {
  color: #5C5C46;
  text-decoration: underline;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #444444;
  background-color: #EBD232;
}

body.editor h3,
#celek .editor h3 {
  font-size: 2.5rem;
  font-family: TekoWeb;
  line-height: normal;
  font-weight: normal;
}

body,
input,
button,
textarea,
select {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #C00000;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  color: #ffffff;
  background-color: #C00000;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

img {
  font-size: 0.75rem;
  max-width: 100%;
  height: auto;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}

/*************************
* ŠABLONY
*************************/
/*************************
* DOKUMENTY
*************************/
/**************************
* DEFINICE PROMĚNNÝCH
**************************/
/*************************/
/**************************
* OBECNÉ
**************************/
html,
body {
  background-color: #f3f3f3;
}

body {
  text-align: center;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.expandable):not(.menu-link):focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2000px #303030 inset, 0 0 0 3px #303030 !important;
  color: #ffffff !important;
  outline: 0;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.centrovany {
  box-sizing: border-box;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  text-align: left;
  padding-left: 3%;
  padding-right: 3%;
}

@media screen and (min-width: 1361px) {
  .centrovany {
    padding-left: 0;
    padding-right: 0;
  }
}

.centrovany.uzsi {
  max-width: 700px;
}

.section {
  padding-top: 15px;
  padding-bottom: 25px;
}

@media screen and (min-width: 801px) {
  .section {
    padding-top: 34px;
    padding-bottom: 54px;
  }
}

@media screen and (min-width: 1025px) {
  .section--narrow {
    padding-left: 6%;
    padding-right: 6%;
  }
}

@media screen and (min-width: 1281px) {
  .section--narrow {
    padding-left: 11%;
    padding-right: 11%;
  }
}

@media screen and (min-width: 601px) {
  .section .section-halves {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.section .section-half {
  margin-bottom: 25px;
}

@media screen and (min-width: 601px) {
  .section .section-half {
    display: table-cell;
    vertical-align: top;
    padding-right: 4%;
  }
  .section .section-half + .section-half {
    padding-left: 4%;
    padding-right: 0;
  }
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
  float: none;
  box-sizing: border-box;
  margin-bottom: 30px;
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

#stranka #stred {
  padding: 0 4% 40px 4%;
}

#stranka #obalcelek {
  background-color: #ffffff;
}

/*************************************
* TABULKOVÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
#stranka #telo {
  padding-top: 30px;
  padding-bottom: 40px;
}

@media screen and (min-width: 651px) {
  #celek::after {
    content: normal;
  }
  #telo {
    display: table;
    border-spacing: 0;
    width: 100%;
  }
  #stranka #telo {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  #telo #stred,
  #telo #vlevo {
    display: table-cell;
    vertical-align: top;
  }
  #telo #vlevo {
    width: 36.5625%;
    padding: 0 2.75%;
  }
  #telo #stred {
    width: auto;
    padding-left: 6.25%;
    padding-right: 20px;
    border-left: 3px #EBD232 solid;
  }
  #stranka #telo {
    direction: rtl;
  }
  #stranka #telo > * {
    direction: ltr;
  }
}

/*************************************
* FLOATOVANÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
/************************
* PRVKY STRÁNKY
************************/
.obalcesta {
  background-color: #FDFABE;
}

.cesta {
  font-size: 0.875rem;
  padding: 16px 2% 16px 2%;
  position: relative;
  color: #000000;
}

.cesta strong {
  font-weight: normal;
}

.cesta a,
.cesta a:link,
.cesta a:visited {
  color: #000000;
}

.box {
  padding: 0 15px 10px 15px;
  background: #cccccc;
  margin: 0 0 15px 0;
}

.wrapper-kalendar {
  background-color: #EBD232;
}

.wrapper-kalendar h2.cvi {
  color: #52523C;
}

.dalsi {
  font-size: 1rem;
  margin: 15px 0 0 0;
  padding: 5px 0 0 0;
  text-align: center;
  text-transform: uppercase;
}

.dalsi a,
.dalsi .btn {
  box-sizing: border-box;
  display: block;
  border-radius: 4px;
  min-width: 242px;
  padding: 12px 3em 10px 3em;
  background: #454533 url(images/more.svg) no-repeat 100% 50%;
}

.dalsi a,
.dalsi a:link,
.dalsi a:visited {
  color: #FFFECC;
  text-decoration: none;
}

.wrapper-kalendar .dalsi a {
  background-color: #454533;
}

.wrapper-kalendar .dalsi a,
.wrapper-kalendar .dalsi a:link,
.wrapper-kalendar .dalsi a:visited {
  color: #FFFECC;
}

.dalsi a:hover,
.dalsi a:focus,
.dalsi a:active {
  text-decoration: underline;
}

.dalsi-inline {
  margin: 15px 0 0 0;
  padding: 5px 0 0 0;
  text-align: left;
}

.dalsi-inline a {
  padding-right: 3em;
  background: transparent url(images/more-inline.svg) no-repeat 100% 50%;
}

.dalsi-inline a,
.dalsi-inline a:link,
.dalsi-inline a:visited {
  text-decoration: underline;
}

@media screen and (min-width: 601px) {
  .dalsi a,
  .dalsi .btn {
    display: inline-block;
  }
}

/************************
* ZÁPATÍ
************************/
#obalnadpatou {
  background: #454533;
  overflow: hidden;
}

#nadpatou {
  color: #D5DFC1;
}

#nadpatou a,
#nadpatou a:link,
#nadpatou a:visited {
  color: #D5DFC1;
}

#nadpatou h2.cvi {
  color: #EBD232;
  font-size: 2.5rem;
}

@media screen and (min-width: 601px) {
  #nadpatou h2.cvi {
    text-align: left;
  }
}

#nadpatou .pata-cols {
  margin: 0 auto;
  padding: 20px 0;
}

#nadpatou .pata-cols .pata-col {
  margin-bottom: 30px;
}

@media screen and (min-width: 901px) {
  #nadpatou {
    margin-left: -25px;
    margin-right: -25px;
  }
  #nadpatou .pata-cols {
    width: 100%;
    table-layout: fixed;
    border-spacing: 25px 0;
    display: table;
  }
  #nadpatou .pata-cols .pata-col {
    padding: 25px 0;
    display: table-cell;
    vertical-align: top;
  }
}

#obalpata {
  background-color: #F8F5B9;
  min-width: 320px;
}

#pata {
  text-align: center;
  padding: 22px 16px 28px;
  color: #272727;
  box-sizing: border-box;
  font-size: 0.875rem;
}

#pata * {
  line-height: 2.2;
  border: 0;
}

#pata li + li::before {
  content: "\|";
  margin: 0 8px 0 0;
}

#pata .partWebmaster1::before {
  content: "\|";
  margin: 0 10px 0 0;
}

#pata a,
#pata a:link,
#pata a:visited {
  color: #324232;
  text-decoration: underline;
}

#pata a:hover,
#pata a:focus,
#pata a:active {
  text-decoration: none;
}

#pata .patalogo {
  padding: 1px 6px;
  margin-right: 11px;
}

#pata #pocitadlo {
  margin: 0 !important;
  padding-bottom: 0 !important;
}

#pata .webmaster,
#pata .inline {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 3px;
}

#pata .webmaster li,
#pata .inline li {
  padding: 0 5px;
}

#pata .webmaster li a,
#pata .inline li a {
  margin: 0 0 0 1px;
}

#pata .webmaster {
  display: block;
}

#stranka #pata .webmaster {
  margin-top: 2px;
}

#pata .webmaster .partWebmaster {
  display: inline-block;
  margin-left: 5px;
}

#pata ul.inline li {
  display: inline-block;
}

#obalmbannery {
  min-width: 320px;
}

@media screen and (max-width: 599px) {
  #pocitadlo li {
    display: block !important;
  }
  #pocitadlo li + li::before {
    content: "";
    margin: 0;
  }
}

@media screen and (max-width: 499px) {
  .partWebmaster1 {
    display: block !important;
    margin-top: 2px;
  }
  .partWebmaster1::before {
    content: "" !important;
    margin: 0 !important;
  }
}

#unpobtekane {
  margin-left: 0 !important;
}

#uvod hr {
  color: #EBD232;
  background-color: #EBD232;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  z-index: 13;
  background-color: #FDFABE;
  color: #000000;
  padding-bottom: 12px;
}

#obalhlava a,
#obalhlava a:link,
#obalhlava a:visited {
  color: #000000;
}

#hlava {
  clear: both;
  position: relative;
  z-index: 3;
  padding-top: 14px;
}

#znak {
  margin: 0;
  max-width: 98px;
  height: auto;
  display: none;
}

.hlava-cols .hlava-col {
  margin: 10px 4% 20px 4%;
}

.hlava-cols .hlava-col + .hlava-col {
  text-align: center;
}

@media screen and (min-width: 601px) {
  .hlava-cols {
    width: 100%;
  }
  .hlava-cols::after {
    content: "";
    display: table;
    width: 100%;
    height: 0;
    clear: both;
  }
  .hlava-cols .hlava-col {
    vertical-align: top;
    float: left;
    margin: 0;
    padding: 0 0 20px 0;
  }
  .hlava-cols .hlava-col:first-child {
    margin-top: -1em;
  }
  .hlava-cols .hlava-col + .hlava-col {
    float: right;
    text-align: right;
  }
}

#nazev {
  text-align: center;
  position: relative;
}

#nazev a {
  z-index: 30;
  display: inline;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}

#nazev a img {
  font-size: 1rem;
  line-height: 1;
}

#nazev a::after {
  content: "";
}

@media screen and (min-width: 601px) {
  #znak {
    position: absolute;
    top: 10px;
    left: 0;
    display: block;
  }
  #nazev {
    text-align: left;
  }
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obrazek {
  display: none;
}

@media screen and (min-width: 601px) {
  #obalobrazek {
    display: block;
    clear: both;
  }
  .wrapper-uvod::after {
    background: #333333 url(images/bg-main-bottom.jpg) no-repeat 50% 100%;
  }
  #obrazek {
    position: relative;
    display: block;
    background: #333333 url(images/bg-main.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  #obrazek #blender {
    z-index: 1;
    position: relative;
    height: 0;
    top: 15px;
    padding-bottom: 40%;
    /*
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: auto;
			}*/
  }
  #obrazek #blender div[id] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto !important;
    height: auto !important;
    background-size: cover !important;
    overflow: visible;
  }
  #obrazek #blender .prolinani-e {
    display: table;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  #obrazek #blender .prolinani-e-spany {
    padding-left: 40px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.3) 90%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  #obrazek #blender .prolinani-e-obrazek,
  #obrazek #blender .prolinani-e-texty {
    display: table-cell;
    vertical-align: middle;
  }
  #obrazek #blender .prolinani-e-texty {
    color: #ffffff;
    font-family: TekoWeb;
  }
  #obrazek #blender .prolinani-e-nazev,
  #obrazek #blender .prolinani-e-popisek {
    display: block;
  }
  #obrazek #blender .prolinani-e-nazev {
    border-top: 4px #ffffff solid;
    font-size: 2.5rem;
    padding: 6px 0 8px 0;
    line-height: .9;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  }
  #obrazek #blender .prolinani-e-nazev:only-child {
    padding-bottom: 12px;
    border-bottom: 4px #ffffff solid;
  }
}

@media screen and (min-width: 601px) and (min-width: 1025px) {
  #obrazek #blender .prolinani-e-nazev {
    font-size: 4.375rem;
  }
}

@media screen and (min-width: 601px) {
  #obrazek #blender .prolinani-e-popisek {
    line-height: 1.1;
    font-size: 1.875rem;
    text-transform: uppercase;
    border-bottom: 4px #ffffff solid;
    padding: 6px 0 8px 0;
  }
  #obrazek #blender .prolinani-e-obrazek {
    box-sizing: border-box;
    width: 40%;
    height: 0;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    border: 7px white solid;
    padding-bottom: 37.88%;
    position: relative;
  }
}

@media screen and (min-width: 601px) and (min-width: 1025px) {
  #obrazek #blender .prolinani-e-obrazek {
    border: 10px white solid;
  }
}

@media screen and (min-width: 601px) {
  #obrazek #blender .prolinani-e-obrazek a,
  #obrazek #blender .prolinani-e-obrazek span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
  }
  #administraceobrazky {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
}

#lista {
  text-align: right;
  padding: 0;
  position: relative;
}

#lista ul {
  position: relative;
}

#lista ul.inline {
  display: inline;
}

#lista li {
  margin: 0;
  white-space: nowrap;
}

#lista li.skip {
  position: absolute;
  display: block;
  top: 0 !important;
  right: 0 !important;
  max-width: 100%;
  padding: 1px;
  border: 0;
}

#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  right: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}

#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

/*************************
* SEZNAMY
*************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
.odkazy .ui li li li::before,
.aktuality2 li.bezobr li li::before,
.utvary .ui li.o li li::before,
.kategorie .ui li li li::before,
.utvary .ui li li li::before,
.galerie .ui li li li::before {
  position: relative;
  top: -0.08em;
  margin-right: 7px;
  display: inline-block;
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23BCBCBC' stroke='%23BCBCBC' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E");
  vertical-align: middle;
  line-height: 0;
  overflow: hidden;
}

li.u, li.typsouboru {
  list-style-type: none !important;
}

li.u::before, li.typsouboru::before {
  content: normal !important;
}

.odkazy ol li::before::before {
  content: normal !important;
}

dl dt {
  font-size: 1.1875rem;
  color: #5C5C46;
  line-height: 1.1;
}

dl.kontakty .utvary {
  padding-top: 4px;
}

.ui li > strong:first-child {
  font-family: TekoWeb;
  font-size: 1.4375rem;
}

.ui li > strong:first-child a,
.ui li > strong:first-child a:link,
.ui li > strong:first-child a:visited {
  color: #5C5C46;
}

.utvary .ui li > strong:first-child,
.bodkazy .ui li > strong:first-child,
.souvisejiciodkazy .ui li > strong:first-child {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.utvary .ui li > strong:first-child a,
.utvary .ui li > strong:first-child a:link,
.utvary .ui li > strong:first-child a:visited,
.bodkazy .ui li > strong:first-child a,
.bodkazy .ui li > strong:first-child a:link,
.bodkazy .ui li > strong:first-child a:visited,
.souvisejiciodkazy .ui li > strong:first-child a,
.souvisejiciodkazy .ui li > strong:first-child a:link,
.souvisejiciodkazy .ui li > strong:first-child a:visited {
  color: #232323;
}

.ui li > strong:first-child img {
  font-size: 0.875rem;
}

.utvary .ui li li > strong:first-child,
.bodkazy .ui li li > strong:first-child,
.souvisejiciodkazy .ui li li > strong:first-child {
  font-weight: normal;
}

.ui ul li > strong:first-child {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1rem;
}

.ui ul li > strong:first-child a,
.ui ul li > strong:first-child a:link,
.ui ul li > strong:first-child a:visited {
  color: #232323;
}

.ui li {
  clear: none;
  padding: 0;
  margin: 0 0 5px 0;
}

.ui li strong {
  font-weight: normal;
  line-height: 1.2;
}

dl.kontakty .utvary .ui li strong + div,
#stromutvaru .utvary .ui li strong + div,
.odkazy .ui li strong + div {
  padding: 0;
  color: #666666;
  line-height: 1.4;
  font-style: italic;
}

dl.kontakty .utvary .ui li strong + div {
  margin-bottom: 10px;
}

.ui li .ui {
  padding-top: 0;
  padding-bottom: 10px;
}

.ui li .ui .ui {
  padding-bottom: 0;
}

.ui li .ui .ui .ui {
  padding-left: 18px;
}

.ui li li {
  margin: 5px 0;
}

.ui li li::after {
  content: normal;
}

.ui ol li {
  padding-left: 0;
}

.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

dl.kontakty .utvary ul.ui {
  margin-left: 0;
  margin-bottom: 15px;
}

dl.kontakty .utvary li strong {
  font-size: 0.9375rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}

dl.kontakty .utvary li strong a,
dl.kontakty .utvary li strong a:link,
dl.kontakty .utvary li strong a:visited {
  color: #232323;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok li {
  display: block;
  margin: 20px 0;
  padding: 0;
}

.dok li:first-child {
  margin-top: 8px;
}

.dok .n5-akce-typ,
.dok .dok-doplnek,
.dok .ktg,
.dok strong + span,
.dok .vd-priloha {
  font-size: 0.9375rem;
  color: #444444;
}

.dok .n5-akce-typ a,
.dok .n5-akce-typ a:link,
.dok .n5-akce-typ a:visited,
.dok .dok-doplnek a,
.dok .dok-doplnek a:link,
.dok .dok-doplnek a:visited,
.dok .ktg a,
.dok .ktg a:link,
.dok .ktg a:visited,
.dok strong + span a,
.dok strong + span a:link,
.dok strong + span a:visited,
.dok .vd-priloha a,
.dok .vd-priloha a:link,
.dok .vd-priloha a:visited {
  color: #5C5C46;
}

.dok .vd-priloha {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}

.dok strong {
  font-weight: normal;
}

.dok strong img {
  margin: 3px 20px 6px 0;
  float: left;
}

.dok strong a {
  font-size: 1.875rem;
  font-family: TekoWeb;
  color: #5C5C46;
  line-height: 1.1;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

#kalendarAkci .dok li.u {
  padding-left: 150px;
}

#kalendarAkci .dok li.u strong img {
  margin-left: -150px;
}

/** DOKUMENTY NA TITULCE **/
.wrapper-novinky .dok li {
  box-sizing: border-box;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 25px;
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 599px) {
  .wrapper-novinky .dok li {
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .wrapper-novinky .dok li {
    width: 48.5%;
    margin-left: 3%;
  }
  .wrapper-novinky .dok li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .wrapper-novinky .dok li {
    width: 22.75%;
    margin-left: 3%;
  }
  .wrapper-novinky .dok li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.wrapper-novinky .dok li > strong {
  text-align: left;
  display: block;
}

.wrapper-novinky .dok li > strong img {
  display: none;
}

.wrapper-novinky .dok li > div {
  margin-top: 8px;
}

@media screen and (min-width: 601px) {
  .wrapper-novinky .dok li {
    border-top: 7px #EBD232 solid;
    padding-top: 25px;
  }
  .wrapper-novinky .dok li > strong {
    padding-bottom: 5px;
    border-bottom: 1px #EBD232 solid;
    text-align: center;
  }
  .wrapper-novinky .dok li > strong img {
    display: block;
  }
  .wrapper-novinky .dok li > div {
    margin-top: 15px;
  }
}

.wrapper-novinky .dok li .dok-doplnek {
  font-style: italic;
}

.wrapper-novinky .dok li img {
  margin-top: 0;
}

@media screen and (min-width: 650px) {
  .wrapper-novinky .skryty {
    display: none;
  }
}

@media screen and (min-width: 501px) {
  .horizontal .dok ul {
    background-color: #ffffff;
    border-left: 10px #EBD232 solid;
  }
}

.horizontal .dok ul li {
  box-sizing: border-box;
  margin: 0;
  padding: 30px 0 0 0;
  width: 100%;
}

.horizontal .dok ul li:last-child {
  padding-bottom: 30px;
}

.horizontal .dok ul li .dok-datum,
.horizontal .dok ul li .dok-telo {
  box-sizing: border-box;
}

.horizontal .dok ul li .dok-datum {
  padding: 1px 0;
  color: #252525;
  display: block;
  font-family: TekoWeb;
  font-size: 1.375rem;
}

.horizontal .dok ul li .dok-doplnek {
  font-style: italic;
}

@media screen and (min-width: 501px) {
  .horizontal .dok ul li {
    display: table;
    padding: 0;
  }
  .horizontal .dok ul li .dok-datum,
  .horizontal .dok ul li .dok-telo {
    box-sizing: border-box;
    display: table-cell;
    vertical-align: top;
  }
  .horizontal .dok ul li .dok-datum {
    font-size: 1.5625rem;
    width: 5.5em;
    border-right: 1px #EBD232 solid;
    padding: 14px 14px 14px 3%;
  }
  .horizontal .dok ul li .dok-telo {
    padding: 14px 25px 14px 5%;
  }
  .horizontal .dok ul li:first-child .dok-telo,
  .horizontal .dok ul li:first-child .dok-datum {
    padding-top: 45px;
  }
  .horizontal .dok ul li:last-child {
    padding-bottom: 0;
  }
  .horizontal .dok ul li:last-child .dok-telo {
    padding-bottom: 25px;
  }
}

.wrapper-uredni-deska {
  background: #e4e4e2 none no-repeat 50% 50%;
  /*@include bp("> 1024px") {
		background-image: url(images/bg-uredni-deska.jpg);
	}*/
}

.wrapper-uredni-deska .dok strong a {
  font-size: 1.5625rem;
}

.wrapper-uredni-deska h2.cvi,
.wrapper-uredni-deska .horizontal {
  display: none;
}

@media screen and (min-width: 601px) {
  .wrapper-uredni-deska h2.cvi,
  .wrapper-uredni-deska .horizontal {
    display: block;
  }
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}

.obrodkazy li::before {
  content: normal !important;
}

.obrodkazy li:nth-of-type(3) ~ li {
  display: none;
}

.obrodkazy a {
  font-size: 1.25rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 65px;
  display: table;
  height: 1em;
  min-height: 48px;
}

.obrodkazy .mtext {
  display: table-cell;
  vertical-align: middle;
}

.obrodkazy strong {
  font-weight: normal;
}

.obrodkazy a,
.obrodkazy a:link,
.obrodkazy a:visited {
  text-decoration: none;
}

.obrodkazy a:hover,
.obrodkazy a:focus,
.obrodkazy a:active {
  text-decoration: underline;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
#vypisakci h3.cvi {
  font-size: 1.5625rem;
  margin-bottom: 10px;
}

#vypisakci .dok li {
  display: table;
  margin: 0 0 44px 0;
  width: 100%;
}

#vypisakci .dok li strong a,
#vypisakci .dok li strong a:link,
#vypisakci .dok li strong a:visited {
  color: #353522;
}

#vypisakci .dok .datum,
#vypisakci .dok .prazdnyDatum {
  display: table-cell;
  vertical-align: top;
  width: 5.52em;
  text-align: center;
}

#vypisakci .dok .den,
#vypisakci .dok .mesic {
  display: block;
  line-height: 1.2;
}

#vypisakci .dok .den {
  font-size: 2.1875rem;
  font-weight: bold;
  background: #454533;
  color: #FDFAAA;
  padding: .05em 0;
  border-radius: 3px 3px 0 0;
}

#vypisakci .dok .mesic {
  font-size: 1rem;
  background: #FDFABE;
  color: #425542;
  text-transform: lowercase;
  padding: .5em 0;
  border-radius: 0 0 3px 3px;
  border-style: solid;
  border-color: #454533;
  border-width: 0 1px 1px 1px;
}

#vypisakci .dok .denPredlozka,
#vypisakci .dok .rok,
#vypisakci .dok .den .tecka {
  position: absolute;
  left: -9999px;
  right: 9990px;
}

#vypisakci .dok .prazdnyDatum {
  background: transparent;
}

#vypisakci .dok .obsahAkce {
  display: table-cell;
  padding-right: 15px;
  padding-left: 15px;
}

#vypisakci .dok .obsahAkce .misto {
  color: #000000;
}

#vypisakci .dok .obsahAkce .typAkce {
  font-style: italic;
  color: #000000;
}

#vypisakci .dok .obsahAkce .typAkce a,
#vypisakci .dok .obsahAkce .typAkce a:link,
#vypisakci .dok .obsahAkce .typAkce a:visited {
  color: #353522;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.titulodkazy:not(:first-child) {
  margin-top: 35px;
}

.titulodkazy li {
  display: block;
  background-image: none;
  margin: 4px 0;
  padding: 0 0 0 28px;
  position: relative;
}

.titulodkazy li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #EBD232;
  position: absolute;
  top: .5em;
  left: 2px;
}

.titulodkazy li a {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1rem;
}

.titulodkazy li strong:first-child a,
.titulodkazy li strong:first-child a:link,
.titulodkazy li strong:first-child a:visited {
  color: #D5DFC1;
}

.titulodkazy li div {
  margin: 2px 0 0 0;
}

.titulodkazy strong {
  font-weight: normal;
}

/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
.titulformular label, #hledani label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}

.titulformular label.label-skryty, #hledani label.label-skryty {
  padding: 0 2px;
  position: relative;
}

.titulformular label.label-skryty::after, #hledani label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  background-color: #FFFEF1;
  color: #425542;
  height: 35px;
  border-radius: 0;
  line-height: 1.21429;
  font-size: 0.9375rem;
}

.format:focus {
  border-color: #7d6f12;
}

#fktg, .fhledani {
  display: none !important;
}

.format,
fieldset {
  border: 1px #D7BE1E solid;
}

:not(.fvlevo) > .fvyraz #hv {
  font-size: 1.1875rem;
  height: 44px;
  border-width: 2px;
}

textarea.format {
  min-height: 70px;
}

select.format[multiple] {
  height: 105px;
}

.btn {
  background-color: #454533;
  border: #454533;
  color: #FFFECC;
  height: 35px;
}

.fbtn .btn {
  padding: 0 2em;
  font-size: 1rem;
}

/************************************************/
.fkont {
  margin-top: 20px;
}

.fkont form {
  padding: 21px 36px 23px 36px;
}

.fkont :not(.fvlevo) > .fvyraz label[for="hv"] {
  font-size: 1rem;
}

.formular,
.fkont form,
.nastaveni {
  background-color: #FDFABE;
  color: #233323;
}

.formular a,
.formular a:link,
.formular a:visited,
.fkont form a,
.fkont form a:link,
.fkont form a:visited,
.nastaveni a,
.nastaveni a:link,
.nastaveni a:visited {
  color: #233323;
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #352d39;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.wrapper-abo {
  background-color: #FDFABE;
}

.titulformular {
  text-align: center;
}

.titulformular label {
  display: inline-block;
  margin-top: 15px;
}

.titulformular label.label-skryty {
  margin-top: 0;
}

.titulformular label.label-skryty::after {
  background: transparent url(images/frm_prekryti.svg) repeat 0 0;
}

.titulformular .format {
  margin: 2px 0;
  padding: 0 15px;
  width: 100%;
  height: 41px;
  color: #425542;
  border-width: 2px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.titulformular .format:focus {
  color: #aa9618;
}

.titulformular .format[name="fabo"] {
  max-width: 430px;
  text-align: center;
  background-position: 50% 0;
}

.titulformular textarea.format {
  padding: 10px 15px;
}

.titulformular img {
  position: absolute;
  right: 0;
  top: 0;
}

.titulformular .btn {
  height: auto;
  font-size: 1rem;
  text-transform: uppercase;
  border: 0;
  float: none;
}

@media screen and (max-width: 500px) {
  .titulformular .btn {
    width: 100%;
  }
}

.titulformular .btn:hover, .titulformular .btn:focus {
  text-decoration: underline;
}

.titulformular p {
  padding: 0;
  margin: 0;
}

/*#napiste {
	max-width: 100%;

	&[class*="toggled"] {
		width: 100%;
		max-width: 700px;
		background-color: #DEE1DA; 
		border-radius: 4px;
		overflow: hidden;
		padding-bottom: 34px;
		z-index: 40;

		textarea {
			border-width: 0 0 2px 0;
			padding: 10px 30px;
			min-height: 164px;
		}

		input.format {
			float: left;
			box-sizing: border-box;
			@include grid-column(2, 3%);
		}

		.jmeno-mail {
			padding-left: 30px;
			padding-right: 30px;
		}

		h2.cvi {
			@extend %vh;
		}

		.napiste-close-wrapper {
			background-color: $color-main;	
			padding: 7px;
			text-align: right;
		}

		.napiste-close-link {
			width: 21px;
			height: 19px;
			display: inline-block;
			cursor: pointer;

			&::before {
				content: encode-svg('<svg width="100%" height="100%" viewBox="0 0 16 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><path d="M0.75,13.5l14.25,-12.75l-14.25,12.75" style="fill:none;stroke:#000;stroke-width:1.5px;"/><path d="M15,13.5l-14.25,-12.75l14.25,12.75" style="fill:none;stroke:#000;stroke-width:1.5px;"/></svg>');
			}
		}
	}
}
*/
/***************
*	HLEDÁNÍ
***************/
#hledani {
  clear: both;
  margin-top: 15px;
}

@media screen and (min-width: 601px) {
  #hledani {
    float: left;
  }
}

#hledani .pole {
  position: relative;
  z-index: 5;
  width: calc(100% - 56px);
}

@media screen and (min-width: 601px) {
  #hledani .pole {
    float: right;
    width: 328px;
  }
}

#hledani label {
  text-align: right;
  display: inline-block;
}

#hledani label.label-skryty::after {
  background: transparent url(images/hl_prekryti.svg) repeat 0 0;
}

#hledani input:focus {
  outline: 0;
}

#hledani .format {
  width: 100%;
  margin: 0;
  height: 36px;
  border-width: 2px;
  border-color: #D7BE1E;
  color: #425542;
  padding: 0 36px 0 10px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

#hledani .btn {
  border: 0;
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 6px;
  height: auto;
  border-radius: 0;
  color: #425542;
  background-color: transparent;
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
}

.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}

.seznam td,
.seznam th {
  border: 1px white solid;
  border-width: 0 1px 1px 0;
}

.seznam td:first-child,
.seznam th:first-child {
  padding-left: 36px;
}

.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}

.seznam th {
  font-size: 1.375rem;
  font-family: TekoWeb;
  line-height: 1.1;
  font-weight: normal;
  background-color: #454533;
  color: #FFFECC;
}

.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #FFFECC;
}

.seznam td {
  color: black;
  border-width: 1px 1px 0 0;
}

.seznam .b1 td {
  border-color: #EBD232;
}

.seznam .b2 td, .seznam #isvs .hodnota td, #isvs .seznam .hodnota td {
  border-color: #EBD232;
}

.b1 {
  background-color: #ffffff;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 399px) {
  .nahledy li {
    width: 100%;
  }
}

@media screen and (min-width: 400px) and (max-width: 799px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .nahledy li {
    width: 23.5%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.nahledy div > a {
  background: #ffffff;
  border: 2px #EBD232 solid;
  border-radius: 4px;
  min-height: 200px;
}

.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #5C5C46;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}

@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

/***********************
*	ZÁLOŽKY
***********************/
.zalozky {
  font-size: 1.5625rem;
  line-height: 1.1;
  font-family: TekoWeb;
  font-weight: normal;
}

.zalozky li {
  padding: 0;
  margin: 0 .5em .5em 0;
  float: left;
  position: relative;
  display: block;
}

.zalozky li.azalozka {
  background: transparent none;
}

.zalozky a {
  display: block;
  text-decoration: none;
  margin: 0;
  position: relative;
  z-index: 20;
  cursor: pointer;
  padding: 10px 36px 12px;
}

.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: transparent;
  color: #444444;
  border: 2px #454533 solid;
}

.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}

.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: #454533;
  color: #FFFECC;
  text-decoration: none;
  cursor: default;
}

#zahlavi2 {
  margin: 20px 0;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

.vych_pol strong {
  font-weight: normal;
}

a[id*="k0"] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}

.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  font-size: 1.3125rem;
  line-height: 1.1;
  font-family: TekoWeb;
  text-transform: uppercase;
}

#titul .zalozky .inline li::before {
  content: normal;
}

#titul .zalozky a {
  padding: 15px;
}

#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: turquoise;
  color: #433923;
}

#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  text-decoration: underline;
}

#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  background-color: bisque;
  color: #000000;
}

@media screen and (max-width: 649px) {
  #linkynakotvy {
    display: none;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
  }
  #titul .poz .skryty {
    display: block;
  }
}

/******************
* MODULY
******************/
.fbOdkaz {
  position: relative;
  float: right;
  margin: 15px 0 0 20px;
}

@-moz-document url-prefix() {
  .fbOdkaz {
    margin: -18px 0 0 20px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .fbOdkaz {
    margin: 0 0 0 20px;
  }
}

@media screen and (min-width: 601px) {
  @-moz-document url-prefix() {
    .fbOdkaz {
      margin: 15px 0 0 20px;
    }
  }
}

@media screen and (min-width: 601px) and (-ms-high-contrast: active), screen and (min-width: 601px) and (-ms-high-contrast: none) {
  .fbOdkaz {
    margin: 15px 0 0 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .fbOdkaz img {
    position: relative;
    margin-top: -50px;
    z-index: 100;
  }
}

#uvod {
  overflow: hidden;
  margin: 0 0 30px 0;
  padding-top: 30px;
  color: #D5DFC1;
}

#uvod.editor h3 {
  color: #EBD232;
  font-size: 2.8125rem;
}

#uvod a,
#uvod a:link,
#uvod a:visited {
  color: #EBD232;
}

.wrapper.wrapper-uvod {
  background-color: #353522;
  margin-bottom: 30px;
}

#kalendarAkci .editor {
  margin-top: 40px;
  margin-bottom: 40px;
}

#mbannery {
  font-size: 0.875rem;
  padding: 30px 0;
  margin: 0;
  color: #000000;
  background: #ffffff;
}

#mbannery .editor {
  text-align: center;
}

#mbannery .editor a,
#mbannery .editor a:link,
#mbannery .editor a:visited,
#mbannery .editor a:hover,
#mbannery .editor a:focus,
#mbannery .editor a:active {
  color: #000000 !important;
}

#mbannery li {
  margin-top: 0;
  margin-bottom: 0;
}

#jmeniny {
  font-size: 0.875rem;
  display: none;
}

@media screen and (min-width: 601px) {
  #jmeniny {
    display: inline-block;
  }
  #jmeniny * {
    display: inline;
  }
}

#kontakt address {
  display: block;
  font-style: normal;
  margin: 0 0 15px 0;
}

#kontakt strong {
  font-weight: bold;
}

#kontakt .iko-link::before {
  display: inline-block;
  width: 47px;
  height: 47px;
  vertical-align: middle;
  margin-right: 18px;
}

#kontakt .iko-link.iko-napiste::before {
  content: url(images/iko_napiste.svg);
}

#kontakt .iko-link.iko-mapa::before {
  content: url(images/iko_mapa.svg);
}

#anketa p {
  padding: 0;
  margin: 0 0 1em 0;
}

#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 5px 0;
}

#anketa ul li {
  margin-bottom: 8px;
}

#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}

#anketa .hlas div {
  height: 12px;
  border-radius: 3px;
  background-color: #EBD232;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}

#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}

#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}

/*******************************
* KALENDÁŘE
*******************************/
.section #kalendar {
  display: none;
}

@media screen and (min-width: 601px) {
  .section #kalendar {
    display: block;
  }
}

/* #kalakci {
	p {
		clear: both;
	} */
#kalakci {
  margin-bottom: 30px;
}

.mesice {
  box-sizing: border-box;
  font-size: 1.875rem;
  display: table;
  width: 100%;
  font-family: TekoWeb;
  line-height: .8;
  border: 1px #454533 solid;
  background-color: #FDFABE;
  table-layout: fixed;
  border-spacing: 0;
  margin-bottom: 10px;
}

.mesice + p {
  margin: 20px 0;
}

.mesice a {
  display: table-cell;
  padding: .35em 0;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  text-transform: lowercase;
}

.mesice a.sipka {
  width: 14.28571%;
  position: relative;
}

.mesice a,
.mesice a:link,
.mesice a:visited {
  color: #425542;
  text-decoration: none;
}

.mesice a:hover,
.mesice a:focus,
.mesice a:active {
  text-decoration: underline;
}

.calcells th {
  font-weight: bold;
  font-size: 1.0625rem !important;
  color: #000000;
  background-color: transparent;
}

.calcells th,
.calcells td {
  padding: 7px 0;
}

.calcells td > a {
  padding: 7px 0;
  margin: -7px 0;
}

.calcells td,
.calcells td > a, .calcells td a:link, .calcells td a:visited {
  background-color: #454533;
  color: #FDFAAA;
}

.calcells td.notmnth {
  background-color: #FDFABE;
  color: #425542;
}

.calcells td.notmnth > a, .calcells td.notmnth a:link, .calcells td.notmnth a:visited {
  background-color: #FDFABE;
  color: #425542;
}

.calcells td.curdate {
  border: 2px #000000 solid;
  padding: 0;
}

.calcells td.curdate > a {
  padding: 5px 0;
  margin: -2px 0;
}

.calcells td.wkday > a:hover, .calcells td.wkday a:focus, .calcells td.wkday a:active, .calcells td.notmnth > a:hover, .calcells td.notmnth a:focus, .calcells td.notmnth a:active {
  background-color: #FDFABE;
  color: #425542;
}

#stranka .calcells th {
  color: #425542;
  background-color: #FDFABE;
}

#stranka .calcells td.wkday,
#stranka .calcells td.wkday > a, #stranka .calcells td.wkday a:link, #stranka .calcells td.wkday a:visited, #stranka .calcells td.wkend,
#stranka .calcells td.wkend > a, #stranka .calcells td.wkend a:link, #stranka .calcells td.wkend a:visited {
  background-color: #454533;
  color: #FDFAAA;
}

#stranka .calcells td > a, #stranka .calcells td a:link, #stranka .calcells td a:visited {
  background-color: #FDFABE;
  color: #425542;
}

#stranka .calcells td.curdate {
  border: 2px #000000 solid;
  border-color: #000000;
}

#stranka .calcells td.wkday > a:hover, #stranka .calcells td.wkday a:focus, #stranka .calcells td.wkday a:active, #stranka .calcells td.wkend > a:hover, #stranka .calcells td.wkend a:focus, #stranka .calcells td.wkend a:active, #stranka .calcells td.notmnth > a:hover, #stranka .calcells td.notmnth a:focus, #stranka .calcells td.notmnth a:active {
  background-color: #FDFABE;
  color: #425542;
}

#stranka .calcells td.cell_selected,
#stranka .calcells td.cell_selected > selector-link-all {
  background-color: #B0030C;
  color: #ffffff;
}

.floatingBubbleElm {
  margin-left: -15px !important;
}

/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#obalmenu1 {
  position: relative;
  z-index: 15;
  padding: 14px 0;
  background-color: #EBD232;
  overflow: hidden;
}

#menu1-vybery {
  background-color: #FDFABE;
  color: #5C5C46;
  padding: 20px 0;
}

#menu1-vybery a,
#menu1-vybery a:link,
#menu1-vybery a:visited {
  color: #5C5C46;
}

#menu1-vybery .centrovany {
  text-align: center;
}

#menu1-vybery h2 {
  font-family: TekoWeb;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 601px) {
  #menu1-vybery h2 {
    font-size: 1.6875rem;
    margin: 0 .2em 0 0;
    display: inline;
  }
}

#menu1-vybery ul {
  font-size: 1.6875rem;
  font-family: TekoWeb;
  font-weight: 300;
  display: inline;
}

#menu1-vybery ul li {
  display: inline;
}

#menu1-vybery ul li + li::before {
  content: "\|";
  margin: 0 .3em 0 .4em;
}

#menu1-vybery ul li .pam {
  display: none;
}

#menu1-vybery ul li a,
#menu1-vybery ul li a:link,
#menu1-vybery ul li a:visited {
  text-decoration: none;
}

#menu1-vybery ul li a:hover,
#menu1-vybery ul li a:focus,
#menu1-vybery ul li a:active {
  text-decoration: underline;
}

@media screen and (min-width: 601px) {
  #menu1 ul {
    display: flex;
    justify-content: center;
  }
  #menu1 li {
    box-sizing: border-box;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 801px) {
  #menu1 ul {
    display: flex;
    justify-content: center;
  }
}

#menu1 li {
  font-size: 2.1875rem;
  line-height: 1.1145;
  margin: 4px 7px;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (min-width: 801px) {
  #menu1 li:nth-last-child(n+2):nth-last-child(-n+2):first-child, #menu1 li:nth-last-child(n+2):nth-last-child(-n+2):first-child ~ li {
    width: 45%;
  }
  #menu1 li:nth-last-child(n+3):nth-last-child(-n+3):first-child, #menu1 li:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ li {
    width: 31%;
  }
  #menu1 li:nth-last-child(n+4):nth-last-child(-n+4):first-child, #menu1 li:nth-last-child(n+4):nth-last-child(-n+4):first-child ~ li {
    width: 22%;
  }
}

#menu1 li.akt a,
#menu1 li.akt a:link,
#menu1 li.akt a:visited,
#menu1 li.akt a:hover,
#menu1 li.akt a:focus,
#menu1 li.akt a:active {
  background-color: #000000;
  text-decoration: none;
  color: #EBD232;
}

#menu1 li.akt a {
  position: relative;
}

@media screen and (min-width: 601px) {
  #menu1 li.akt a::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: -16px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #000000 transparent transparent transparent;
  }
}

#menu1 li a {
  display: block;
  font-family: TekoWeb;
  padding: 5px 20px 4px 20px;
  border-radius: 2px;
  background-color: #454533;
  font-weight: normal;
}

@media screen and (min-width: 601px) {
  #menu1 li a {
    padding-top: 11px;
    padding-bottom: 10px;
    font-weight: 300;
  }
}

#menu1 li a,
#menu1 li a:link,
#menu1 li a:visited {
  text-decoration: none;
  color: #FDFAAA;
}

#menu1 li a:hover,
#menu1 li a:focus,
#menu1 li a:active {
  background-color: #000000;
  color: #EBD232;
}

#menu1 li #osmakth,
#menu1 li #osmakth:link,
#menu1 li #osmakth:visited,
#menu1 li #osmakth:hover,
#menu1 li #osmakth:focus,
#menu1 li #osmakth:active {
  cursor: text;
  background-color: #000000;
  color: #EBD232;
  text-decoration: none;
}

/*********************
* MENU VE SLOUPCI
*********************/
.menu-controls,
#menu-start {
  display: none;
}

.nadmenu,
#nadmenu {
  display: none;
}

/**********************
* MOBILNÍ MENU
**********************/
#menu {
  background-color: #ffffff;
  padding: 20px;
}

#menu > ul a {
  padding: 5px 20px;
  margin-left: -20px;
  display: block;
}

#menu > ul a,
#menu > ul a:link,
#menu > ul a:visited {
  color: #5C5C46;
}

#menu > ul #osmakt,
#menu > ul #osmakt:link,
#menu > ul #osmakt:visited,
#menu > ul #osmakt:hover,
#menu > ul #osmakt:focus,
#menu > ul #osmakt:active {
  text-decoration: none;
}

#menu ul {
  padding-left: 20px;
}

@media screen and (max-width: 799px) {
  #menu-start {
    display: block;
    clear: both;
    text-align: center;
    margin-bottom: 7px;
    padding: 15px;
  }
  #menu-start a {
    background: #454533;
    display: block;
    padding: 15px 0;
  }
  #menu-start a::before {
    display: inline-block;
    vertical-align: middle;
    margin: -.15em .75em 0 0;
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='22' viewBox='0 0 30 22'%3E%3Cpath fill='%23FFFECC' fill-rule='evenodd' clip-rule='evenodd' d='M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z'/%3E%3C/svg%3E");
  }
  #menu-start a,
  #menu-start a:link,
  #menu-start a:visited {
    color: #FFFECC;
    text-decoration: none;
  }
  .no-scroll {
    height: 100%;
    touch-action: none;
    overflow: hidden;
    pointer-events: none;
    -webkit-overflow-scrolling: auto;
  }
  .no-scroll body {
    height: 100%;
    touch-action: none;
    overflow: hidden;
  }
  #menu.menu-initialised {
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 200;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-width: 400px;
    height: 100vh;
    padding: 70px 20px 20px 0;
    transition: transform .3s ease-out;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: -webkit-transform .3s ease-out;
  }
  [data-env="Safari"] #menu.menu-initialised {
    max-width: 100%;
  }
  #menu.menu-initialised.menu-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  #menu.menu-initialised > ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 0 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 101;
  }
  #menu.menu-initialised > ul > li {
    margin-bottom: 15px;
  }
  #menu.menu-initialised > ul > li:last-child {
    margin-bottom: 80px;
  }
  #menu.menu-initialised ul {
    list-style-type: none;
  }
  #menu.menu-initialised .menu-controls {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: block;
    height: 50px;
    z-index: 100;
    background-color: #454533;
    color: #FFFECC;
  }
  #menu.menu-initialised .menu-controls .menu-control-back,
  #menu.menu-initialised .menu-controls .menu-control-title {
    display: block;
  }
  #menu.menu-initialised .menu-controls .menu-control-back {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    top: 0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23FFFECC' fill-rule='evenodd' d='M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 22px 22px;
  }
  #menu.menu-initialised .menu-controls .menu-control-title {
    padding: 0 20px;
    line-height: 2.2;
    border-right: 70px transparent solid;
    white-space: nowrap;
    font-size: 1.25rem;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #menu.menu-initialised .menu-indicator {
    display: none;
  }
}

/***********************
* DESKTOPOVÉ MENU
***********************/
@media screen and (min-width: 800px) {
  .nadmenu {
    display: block;
  }
  #nadmenu {
    font-size: 0.875rem;
    display: none;
    font-weight: normal;
    padding: 0;
    white-space: nowrap;
  }
  #menu {
    background-color: #ffffff;
    padding: 0 0 50px 0;
  }
  #menu h2.cvi {
    background-color: #460432;
    padding: 10px 25px 10px 40px;
    font-size: 1.75rem;
    color: #000000;
    font-weight: normal;
  }
  #menu .menu-item-collapsed > ul {
    display: none;
  }
  #menu .menu-item-expanded > a .menu-indicator {
    transform: rotate(225deg);
  }
  #menu .menu-item-expanded > ul {
    display: block;
  }
  #menu .menu {
    font-size: 1.875rem;
    font-family: TekoWeb;
    line-height: 1.2;
    padding: 0 40px 5px 40px;
  }
  #menu .menu ul {
    text-transform: none;
  }
  #menu .menu a,
  #menu .menu a:link,
  #menu .menu a:visited {
    color: #5C5C46;
    text-decoration: none;
  }
  #menu .menu ul a,
  #menu .menu ul a:link,
  #menu .menu ul a:visited {
    color: #444444;
  }
  #menu .menu ul a:hover,
  #menu .menu ul a:focus,
  #menu .menu ul a:active {
    text-decoration: underline;
  }
  #menu .menu #osmakt,
  #menu .menu #osmakt:link,
  #menu .menu #osmakt:visited,
  #menu .menu #osmakt:hover,
  #menu .menu #osmakt:focus,
  #menu .menu #osmakt:active {
    font-weight: bold;
    background-color: transparent;
    text-decoration: none;
    cursor: default;
    border: 0;
  }
  #menu .menu a {
    padding: 13px 0 13px 0;
    display: block;
    position: relative;
    z-index: 10;
    margin-left: 0;
    text-decoration: none;
  }
  #menu .menu .menu-indicator {
    width: 21px;
    height: 21px;
    overflow: hidden;
    position: absolute;
    top: .6em;
    left: -30px;
    z-index: 10;
    -webkit-transition: -webkit-transform .25s;
    transition: -ms-transform .25s;
    transition: transform .25s;
  }
  #menu .menu .menu-indicator img {
    margin: 0;
    cursor: pointer;
    font-size: 0.875rem;
  }
  #menu .menu ul {
    font-size: 1rem;
    font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
    margin: 0 0 0 -40px;
    padding: 2px 0 12px 0;
  }
  #menu .menu ul a {
    padding: 1px 15px 0 40px;
    line-height: 1.5;
  }
  #menu .menu ul .menu-indicator {
    left: 13px;
    top: .23em;
    width: 16px;
    height: 16px;
  }
  #menu .menu ul ul {
    margin: 0;
    padding: 8px 0 0 24px;
    position: static;
  }
  #menu li {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }
  #menu li li {
    border: 0;
    margin-bottom: 6px;
    font-weight: normal;
  }
}

/***************************************
*	POČASÍ - OPENWEATHERMAP - MAX 5 DNÍ
***************************************/
.owm {
  text-align: left;
  list-style-type: none;
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 1rem;
}

.owm span[class*="owm"] {
  display: block;
}

.owm .owmIcon {
  padding: .5em 0;
}

.owm li {
  display: inline-block;
  padding: 0;
  margin: 0 1em;
}

.owm.owm-1 li .owmDateDayName, .owm.owm-2 li .owmDateDayName, .owm.owm-3 li .owmDateDayName {
  display: none;
}

.owm.owm-4 li .owmDateDay, .owm.owm-5 li .owmDateDay {
  display: none;
}

.owm.owm-1 li:nth-of-type(1) ~ li {
  display: none;
}

.owm.owm-2 li:nth-of-type(2) ~ li {
  display: none;
}

.owm.owm-3 li:nth-of-type(3) ~ li {
  display: none;
}

.owm.owm-4 li:nth-of-type(4) ~ li {
  display: none;
}

.owm.owm-5 li:nth-of-type(5) ~ li {
  display: none;
}

.owm .owmTempMax {
  font-size: 1.1875rem;
}

.owm img {
  width: auto;
  height: auto;
  max-width: 48px;
}

#pocasi a {
  padding-top: 0;
  padding-left: 0 !important;
}

#google_translate_element {
  display: inline-block;
  position: relative;
  top: -1px;
  margin-left: 20px;
}

#google_translate_element .goog-te-gadget-icon {
  border-radius: 2px;
}

#google_translate_element .goog-te-gadget-simple {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
}

#google_translate_element .goog-te-gadget-simple,
#google_translate_element .goog-te-gadget-simple * {
  font-size: 0.875rem !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] {
  margin-right: 0;
  display: inline-block;
  text-decoration: none !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span.gsp1 {
  padding: 0 3px;
  display: inline-block;
  color: inherit;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span.gsp2 {
  padding-right: 3px;
  border-left: 0 !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span.gsp3 {
  background: none;
  color: inherit !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] img {
  display: none;
}

@media screen and (max-width: 599px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame {
    display: none !important;
  }
}
